import { useEffect, useRef } from 'react'
import { useLocation, useParams} from 'react-router-dom'

import { MixpanelContext } from './MixpanelProvider'
import { useMixpanel } from '@hooks/useMixpanel'
import { getDefaultMixpanelParams, getNormalizedPageName } from '@utils/mixpanel'

export const withMixpanelContext = (Component: any) => {
  return (props: any) => {
    const location = useLocation();
    const params = useParams();
    const mixpanel = useMixpanel();

    const usePrevious = (value: any) => {
      const ref = useRef();
      useEffect(() => {
        ref.current = value;
      });
      return ref.current;
    }

    const prevLocation = usePrevious(location.pathname);

    useEffect(() => {
      if (prevLocation !== location.pathname) {
        const routeParams = Object.entries(params);
        const urlParams = new URLSearchParams(location.search);
        const paramsForMixpanel = getDefaultMixpanelParams(routeParams, urlParams);
        const pageName = getNormalizedPageName(routeParams, location.pathname)
        mixpanel?.track_pageview({ page: pageName, ...paramsForMixpanel })
      }
    },[location]);
    
    return (
      <MixpanelContext.Consumer>
        {context => <Component {...props} context={context} />}
      </MixpanelContext.Consumer>
    )
  }
}
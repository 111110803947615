import { type ProviderProps, createContext } from 'react';
import mixpanel, { type Mixpanel } from 'mixpanel-browser';
import { FEATURE_FLAGS } from '@constants/featureFlags';
import { MIXPANEL_ENVIRONMENT, MIXPANEL_TOKEN } from '@constants/mixpanel';
import { withMixpanelContext } from './MixpanelConsumer';

export type MixpanelContext = Mixpanel | undefined;

export interface MixpanelProviderProps extends Omit<ProviderProps<MixpanelContext>, 'value'> {}

export const MixpanelContext = createContext<MixpanelContext>(undefined)

export const wrapRouteElements = (routes: any[]) => {
  return routes.map((r) => {
    const WrappedElement = withMixpanelContext(r.element)
    return {
      ...r,
      element: <WrappedElement/>
    }
  })
}

export const MixpanelProvider = ({ children }: MixpanelProviderProps) => {
  const config = MIXPANEL_ENVIRONMENT === 'development' ? {debug: true} : {debug: false}
  const mx = FEATURE_FLAGS.mixpanelEnabled ? mixpanel.init(MIXPANEL_TOKEN, config, 'react-mixpanel') : undefined

  return <MixpanelContext.Provider value={mx}>{children}</MixpanelContext.Provider>;
}
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    dsn: (document.querySelector('meta[name="sentry-dsn"]') as HTMLMetaElement)?.content,
    integrations: [
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: parseFloat(
      (document.querySelector('meta[name="sentry-traces-sample-rate"]') as HTMLMetaElement)
        ?.content,
    ),
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 0.0,
    allowUrls: [
      ...JSON.parse(
        (document.querySelector('meta[name="sentry-allowed-urls"]') as HTMLMetaElement)?.content ||
          '[]',
      ),
    ],
  });
}

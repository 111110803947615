import { ALLOWED_ROUTE_PARAMS, ALLOWED_URL_PARAMS, URL_PARAMS_MAP } from "@constants/mixpanel";
import { decamelize } from "humps";

export type RouteParamsArray = Array<[string, string | undefined]>

// Create a normalized page name from the path name by replacing any route params values with their keys.
// e.g. "agent-name" is replaced with "agentSlug"
export const getNormalizedPageName = (routeParams: RouteParamsArray, pathName: string = ""): string => {
  return routeParams.reduce((path, [key, value]) => {
    return value ? path.replace(value, `:${key}`) : path;
  }, pathName);
} 

// Construct an object of default params to log to Mixpanel, derived from the route and URL params.
// Route params are camelized, whereas URL params are not.
export const getDefaultMixpanelParams = (routeParams: RouteParamsArray, urlParams: URLSearchParams): object => {
  const paramsForMixpanel: any = {};

  routeParams.reduce((map, [key, value]) => {
    if (ALLOWED_ROUTE_PARAMS.includes(key)) {
      map[decamelize(key)] = value
    }
    return map
  }, paramsForMixpanel);

  urlParams.forEach((value, key) => {
    if (ALLOWED_URL_PARAMS.includes(key)) {
      const formattedKey = URL_PARAMS_MAP[key as keyof typeof URL_PARAMS_MAP]
      paramsForMixpanel[formattedKey || key] = value
    }
  })

  return paramsForMixpanel;
}
import axios, { AxiosRequestTransformer, AxiosResponseTransformer } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

const csrfToken = () =>
  document.querySelector("meta[name='csrf-token']")?.getAttribute('content') ?? '';

const headers = {
  'X-CSRF-TOKEN': csrfToken(),
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const decamelizeReqestKeys = (data: object) => decamelizeKeys(data);
const camelizeResponseKeys = (data: object) => camelizeKeys(data);

const transformRequest = [
  decamelizeReqestKeys,
  ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
];

const transformResponse = (axios.defaults.transformResponse as AxiosResponseTransformer[]).concat([
  camelizeResponseKeys,
]);

export const httpService = axios.create({
  timeout: 30000,
  headers,
  transformRequest,
  transformResponse,
});

// Add a response interceptor to handle session expiration
httpService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response?.status === 401 &&
      (error.response.data?.error?.message ===
        'You need to sign in or sign up before continuing.' ||
        error.response.data?.error?.message ===
          'Your session expired. Please sign in again to continue.')
    ) {
      // Redirect to login page
      location.reload();
    }
    return Promise.reject(error);
  },
);
